<template>
  <v-app id="inspire">
    <Header v-model="expandOnHover"/>
    <v-main>
      <v-container fluid class="page-wrapper">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </v-container>
    </v-main>
    <Sidebar :expand-on-hover.sync="expandOnHover"/>
    <right-sidebar/>
  </v-app>
</template>

<script>
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import RightSidebar from "./sidebar/RightSidebar";
import { mapState, mapMutations } from "vuex";
import defineRulesFor from "../../ability";

export default {
  name: "Layout",
  title: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : "Perluta",
  components: {
    Header,
    Sidebar,
    RightSidebar,
  },
  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: false,
  }),
  computed: {
    ...mapState(["RightSidebar"]),
  },
  created() {
    let user = this.$store.getters.getUser;
    if (this.$Tawk.$isTawkUrlAssign() && (user.type == "admin" || user.type == "employee")) {
      this.$Tawk.$createChat();
      this.$Tawk.$updateChatUser({
        name: user.person.name,
        email: user.email,
        hash: user.email_hash,
      });
    }
    this.loadingAssignPermission();
    this.getInfo();
  },
  methods: {
    ...mapMutations({
      setRightSidebar: "SetRightSidebar",
    }),
    getInfo() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/info")
        .then((response) => {
          this.loading = false;
          this.info = response.data;
          this.$store.dispatch("setColors", {
            primary_color: response.data.primary_color,
            secondary_color: response.data.secondary_color,
          });
          this.$vuetify.theme.themes.light.primary = response.data.primary_color;
          this.$vuetify.theme.themes.light.secondary = response.data.secondary_color;
          if (this.$store.getters.getReleaseVersion !== response.data.release_version) {
            this.$store.dispatch("refreshTranslations");
          }
          this.$store.dispatch("setReleaseVersion", response.data.release_version);
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    loadingAssignPermission() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/abilities")
        .then((response) => {
          this.ability.update(defineRulesFor(response.data));
        }).catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
  },
  watch: {
    $route: {
      handler() {
        this.loadingAssignPermission();
      },
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>