import icons from './plugins/icons'
import store from './store'
export default [
    {
        icon: icons['mdi-desktop-mac-dashboard'],
        title: "Dashboard",
        gate: { subject: 'dashboard', action: 'showall' },
        to: "/",
    },
    {
        icon: icons['projects'],
        gate: { subject: 'project', action: 'showall' },
        title: store.getters.translate("projects"),
        to: "/projects",
    },
    {
        icon: icons['punchlists'],
        gate: { subject: 'punchlist', action: 'showall' },
        title: store.getters.translate("punchlists"),
        to: "/punchlists",
    },
    {
        icon: icons['planner'],
        gate: { subject: 'planning', action: 'showall' },
        title: store.getters.translate("planner"),
        to: "/planning",
    },
    {
        icon: icons['platforms'],
        gate: { subject: 'platform', action: 'showall' },
        title: store.getters.translate("platforms"),
        to: "/platforms",
    },
    {
        icon: icons['documents'],
        gate: { subject: 'document', action: 'showall' },
        title: store.getters.translate("documents"),
        to: "/documents",
    },
    {
        icon: icons['notes'],
        gate: { subject: 'note', action: 'showall' },
        title: store.getters.translate("notes"),
        to: "/notes",
    },
    {
        icon: icons['search'],
        gate: { subject: 'search', action: 'showall' },
        title: store.getters.translate("search"),
        to: "/search",
    },
    {
        header: store.getters.translate("relations_management"),
        gate: { action: ['company'] }
    },
    {
        icon: icons['companies'],
        gate: { subject: 'company', action: 'showall' },
        title: store.getters.translate("companies"),
        to: "/companies",
    },
    {
        icon: icons['contacts'],
        gate: { subject: 'contact', action: 'showall' },
        title: store.getters.translate("contacts"),
        to: "/contacts",
    },
    // {
    //     icon: icons['templates'],
    //     gate: { subject: 'template', action: 'showall' },
    //     title: store.getters.translate("templates"),
    //     to: "/templates",
    // },
    {
        header: store.getters.translate("settings"),
        gate: { action: ['translation', 'field', 'role', 'workflow'] }
    },
    {
        icon: icons['translations'],
        gate: { subject: 'translation', action: 'showall' },
        title: store.getters.translate("translations"),
        to: "/translations",
    },
    {
        icon: icons['fields'],
        gate: { subject: 'field', action: 'showall' },
        title: store.getters.translate("fields"),
        to: "/fields",
    },
    {
        icon: icons['tags'],
        gate: { subject: 'tag', action: 'showall' },
        title: store.getters.translate("tags"),
        to: "/tags",
    },
    {
        icon: icons['typesandsubtypes'],
        gate: { subject: 'typesandsubtype', action: 'showall' },
        title: store.getters.translate("typesandsubtypes"),
        to: "/typesandsubtypes",
    },
    {
        icon: icons['forms'],
        gate: { subject: 'form', action: 'showall' },
        title: store.getters.translate("forms"),
        to: "/forms",
    },
    {
        icon: icons['equipments'],
        gate: { subject: 'equipment', action: 'showall' },
        title: store.getters.translate("equipments"),
        to: "/equipment",
    },
    {
        icon: icons['fieldsets'],
        gate: { subject: 'fieldset', action: 'showall' },
        title: store.getters.translate("fieldsets"),
        to: "/fieldsets",
    },
    {
        icon: icons['reportsections'],
        gate: { subject: 'reportsection', action: 'showall' },
        title: store.getters.translate("reportsections"),
        to: "/reportsections",
    },
    {
        icon: icons['importassets'],
        gate: { subject: 'importassets', action: 'showall' },
        title: store.getters.translate("importassets"),
        to: "/importassets",
    },
    {
        icon: icons['employees'],
        gate: { subject: 'employee', action: 'showall' },
        title: store.getters.translate("employees"),
        to: "/employees",
    },
    {
        icon: icons['roles'],
        gate: { subject: 'role', action: 'showall' },
        title: store.getters.translate("roles"),
        to: "/roles",
    },
    {
        icon: icons['workflows'],
        gate: { subject: 'workflow', action: 'showall' },
        title: store.getters.translate("workflows"),
        to: "/workflows",
    },
    {
        icon: icons['settings'],
        gate: { subject: 'settings', action: 'showall' },
        title: store.getters.translate("settings"),
        to: "/settings",
    },
    {
        icon: icons['documentation'],
        gate: { subject: 'documentation', action: 'showall' },
        title: store.getters.translate("documentation"),
        to: "/documentation",
    },
]